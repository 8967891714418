<!-- 开发者平台组件 -->
<template>
  <div class="mobile-developer">
    <div class="item" v-for="item in developerData" :key="item.id" @click="goDetail(item.id)">
      <div class="title">{{ item.title }}</div>
      <div class="descript">{{ item.descript }}</div>
    </div>
  </div>
</template>

<script>
  import DeveloperData from './developer-data'

  export default {
    name: 'MobileDeveloper',
    data () {
      return {
        developerData: DeveloperData
      }
    },
    methods: {
      // 跳转至详情页
      goDetail (id) {
        this.$router.push({
          path: `/mobileDeveloperDetail/${id}`
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/components/mobile/style/variable.scss';

  .mobile-developer {
    padding-left: $distance-1;
    padding-right: $distance-1;
    // padding-bottom: 1rem;
    .item {
      width: 7.1rem;
      height: 1.82rem;
      margin-bottom: 1rem;
      padding-left: 0.4rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: Microsoft YaHei;
      color: $white-color;
      font-weight: 400;
      background: url(~@/assets/image/mobile/bg-12.png) no-repeat;
      background-size: 100%;
      position: relative;
      .title {
        font-size: $font-size-1;
        font-weight: bold;
      }
      .descript {
        margin-top: 0.25rem;
        font-size: $font-size-3;
        color: rgba(255, 255, 255, 0.5);
      }
      &:after {
        content: '';
        width: 2.28rem;
        height: 2.18rem;
        position: absolute;
        right: 0;
        bottom: 0.07rem;
      }
      &:nth-of-type(1):after {
        background: url(~@/assets/image/mobile/photo-59.png) no-repeat;
        background-size: 100%;
      }
      &:nth-of-type(2):after {
        background: url(~@/assets/image/mobile/photo-60.png) no-repeat;
        background-size: 100%;
      }
      &:nth-of-type(3):after {
        background: url(~@/assets/image/mobile/photo-61.png) no-repeat;
        background-size: 100%;
      }
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
</style>