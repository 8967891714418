export default [
  {
    id: '1',
    name: '产品与服务',
    select: true,
    children: [
      {
        id: '1_1',
        name: '规划集成',
        url: '/mobileProductServiceDetail/1'
      },
      {
        id: '1_2',
        name: '信息系统',
        url: '/mobileProductServiceDetail/2'
      },
      {
        id: '1_3',
        name: '智能装备',
        url: '/mobileProductServiceDetail/3'
      }
    ]
  },
  {
    id: '2',
    name: '解决方案',
    select: false,
    children: [
      {
        id: '2_1',
        name: '医药生产',
        url: '/mobileSolutionDetail/1'
      },
      {
        id: '2_2',
        name: '医药流通',
        url: '/mobileSolutionDetail/2'
      },
      {
        id: '2_3',
        name: '医药连锁',
        url: '/mobileSolutionDetail/3'
      },
      {
        id: '2_4',
        name: '医疗器械',
        url: '/mobileSolutionDetail/4'
      },
      {
        id: '2_5',
        name: '食品生鲜',
        url: '/mobileSolutionDetail/5'
      },
      {
        id: '2_6',
        name: '三方物流',
        url: '/mobileSolutionDetail/6'
      }
    ]
  },
  {
    id: '3',
    name: 'SAAS云服务',
    select: false,
    children: [
      {
        id: '3_1',
        name: 'OMS订单管理系统',
        url: '/mobileCloudPlatformDetail/1'
      },
      {
        id: '3_2',
        name: 'TMS运输管理系统',
        url: '/mobileCloudPlatformDetail/3'
      },
      {
        id: '3_3',
        name: 'WMS仓储管理系统',
        url: '/mobileCloudPlatformDetail/2'
      },
      {
        id: '3_4',
        name: 'BMS计费管理系统',
        url: '/mobileCloudPlatformDetail/4'
      },
      {
        id: '3_5',
        name: 'OAS运营分析系统',
        url: '/mobileCloudPlatformDetail/5'
      }
    ]
  },
  {
    id: '4',
    name: '开发者平台',
    select: false,
    children: [
      {
        id: '4_1',
        name: 'MIP网格集成平台',
        url: '/mobileDeveloperDetail/1'
      },
      {
        id: '4_2',
        name: 'TDP技术开发平台',
        url: '/mobileDeveloperDetail/2'
      },
      {
        id: '4_3',
        name: 'DDP数据开发平台',
        url: '/mobileDeveloperDetail/3'
      }
    ]
  }
]