<!-- 公用头部 -->
<template>
  <div class="mobile-header">
    <div class="mobile-header-menu">
      <div class="logo" @click="goMobileHome">
        <img src="@/assets/image/mobile/logo.png">
      </div>
      <div class="menu">
        <img src="@/assets/image/mobile/menu.png" v-show="!showMenu" @click="showMainMenu">
        <img src="@/assets/image/mobile/close.png" v-show="showMenu" @click="showMenu = false">
      </div>
    </div>
    <div class="mobile-header-body" v-show="showMenu">
      <div class="menu-main">
        <span v-for="item in menus" :key="item.id" :class="{'active': item.select}" @click="showSubMenu(item)">{{ item.name }}</span>
      </div>
      <div class="menu-sub">
        <span v-for="item in subMenu" :key="item.id" @click="go(item)">{{ item.name }}</span>
      </div>
      <!-- <img src="@/assets/image/mobile/up.png" class="menu-hide" @click="showMenu = false"> -->
    </div>
  </div>
</template>

<script>
  import Menus from './menu-data'

  export default {
    name: 'MobileHeader',
    data () {
      return {
        showMenu: false,
        menus: Menus,
        subMenu: Menus[0].children
      }
    },
    methods: {
      // 调整首页
      goMobileHome () {
        this.$router.push({
          path: '/mobileHome'
        })
      },
      // 显示菜单
      showMainMenu () {
        this.showMenu = true
        let arr = this.menus.filter(item => item.select)
        if (arr.length > 0) {
          this.getSubMenu(arr[0].id)
        }
      },
      // 获取二级菜单
      getSubMenu (id) {
        this.subMenu = this.menus.filter(item => item.id === id)[0].children
      },
      // 显示子菜单
      showSubMenu (row) {
        this.menus.forEach(item => {
          item.select = false
        })
        row.select = true
        this.getSubMenu(row.id)
      },
      // 跳转页面
      go (row) {
        this.showMenu = false
        if (this.$route.path !== row.url) {
          this.$router.push({
            path: row.url
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/components/mobile/style/variable.scss';

  .mobile-header {
    .mobile-header-menu {
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $distance-1;
      background-color: $white-color;
      border-bottom: 1px solid $border-color-2;
      box-sizing: border-box;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .logo {
        width: 2rem;
        height: 0.37rem;
      }
      .menu {
        width: 0.34rem;
        height: 0.34rem;
      }
    }
    .mobile-header-body {
      height: calc(100vh - 0.88rem);
      background-color: $white-color;
      display: flex;
      font-size: $font-size-3;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: $text-color-1;
      position: relative;
      .menu-main {
        background-color: $page-color;
        width: 2rem;
        padding-top: $distance-1;
        span {
          display: block;
          height: 0.88rem;
          line-height: 0.88rem;
          padding: 0 $distance-1;
          white-space: nowrap;
          position: relative;
          &.active {
            background-color: $white-color;
            font-weight: bold;
          }
          &.active:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.17rem;
            width: 0.04rem;
            height: 0.34rem;
            background: $text-color-6;
          }
        }
      }
      .menu-sub {
        flex: 1;
        padding: 0.26rem 0.44rem;
        span {
          display: block;
          height: 0.88rem;
          line-height: 0.88rem;
          padding: 0 0.05rem;
          border-bottom: 1px solid $border-color-2;
        }
      }
      // .menu-hide {
      //   display: block;
      //   width: 0.68rem;
      //   height: 0.68rem;
      //   position: absolute;
      //   bottom: 0.6rem;
      //   left: 3.24rem;
      // }
    }
  }
</style>