export default [
  {
    id: '1',
    title: '网格集成平台',
    descript: '各信息系统实现快速无缝集成',
    simple: 'MIP',
    detail: {
      image: '43',
      imageWidth: 12.65,
      imageHeight: 5.65,
      title: '统一技术平台支撑，统一服务开放接入，统一系统运营管理及全自动化系统运维',
      discript: [
        '基于浏览器集成工具，在线设计服务流程、BMP业务流程，在线组装具有业务含义应用系统',
        '基于服务总线和消息总线的多类型数据开放接入，结合数据运算流程编排组装及配置化的数据运算调度方式，可快速实现企业的多个子系统间的互联互通，无缝集成',
        '网格服务、大数据架构，具有高频次调用与海量数据处理能力。服务与应用灵活部署，灵活扩展'
      ]
    }
  },
  {
    id: '2',
    title: '技术开发平台',
    descript: '构建企业业务中台与组装应用系统',
    simple: 'TDP',
    detail: {
      image: '44',
      imageWidth: 10.29,
      imageHeight: 5.67,
      title: '从需求到集成发布全流程敏捷化管理，研发过程与研发成果闭环管控，降低协同研发成本，实现快速交付目标',
      discript: [
        '基于WEB的集成开发环境，前端、服务在线可视化开发与调试，服务流程与数据运算流程自定义在线编排，一键发布部署',
        '从数据建模到应用功能的全系列开发组件，统一技术底层容器与服务引擎，让研发人员更关注于业务，研发效率60%以上',
        '支持多类型的原生开源系统，基于微服务架构，模块独立、高度复用、灵活组合扩展，可快速响应业务发展需求'
      ]
    }
  },
  {
    id: '3',
    title: '数据开发平台',
    descript: '构建企业数据中台与数据应用系统',
    simple: 'DDP',
    detail: {
      image: '45',
      imageWidth: 7.99,
      imageHeight: 5.7,
      title: '实现数据集中治理，全维打通融合，分域管理。实现数据与业务的闭环，形成企业数据资产，适用经营与运营中的实时监控、即席查询，分析诊断决策与智能应用',
      discript: [
        '从数据交换、数据治理、数据开发、数据资产可配置化或低代码开发工具集，实现数据全生命周期链路的应用处理，快速实现企业数据中台',
        '可视化界面操作，服务在线开发、全SQL表达，运算流程在线编排、内置丰富的物流行业数据模型与展示组件，降低数据使用门槛与人员能力要求，提高开发效率50%以上',
        '大数据架构，支持多类型的数据源，支持高频次、高吐量、海量数据的处理。支持定时、事件驱动多类型的运算调度策略，支持批量与流式等多种运算方式'
      ]
    }
  }
]