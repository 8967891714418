<!-- 公用底部 -->
<template>
  <div class="mobile-footer">
    <a href="tel:027-84683043" class="phone">
      <img src="@/assets/image/mobile/phone.png">
      <span>400-800-4488转84888</span>
    </a>
    <div class="link-way">
      <div class="email">
        <img src="@/assets/image/mobile/email.png">
        <span>Inz56@jztey.com</span>
      </div>
      <div class="address">
        <img src="@/assets/image/mobile/address.png">
        <span>湖北省武汉市东西湖区田园大道99号</span>
      </div>
    </div>
    <div class="copyright">
      <span>Copyright 2021 www.56jzt.com <a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备17001640号-1</a></span>
      <span><a href="https://beian.miit.gov.cn" target="_blank">
          <div class="icon" style="display: inline-block;">
            <img src="@/assets/image/footer/gov-img.png">
          </div>
          鄂公网安备 42011202002169号
        </a></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileFooter'
}
</script>

<style lang="scss" scoped>
@import '@/components/mobile/style/variable.scss';

.mobile-footer {
  color: $text-color-3;

  img {
    width: 0.26rem;
    height: 0.26rem;

    &+span {
      margin-left: $distance-1;
    }
  }

  .phone {
    color: $text-color-3;
    width: 5.6rem;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $text-color-3;
    border-radius: 2rpx;
    margin: 0 auto;
    font-size: $font-size-3;
    font-weight: 600;
  }

  .link-way {
    margin-top: 0.34rem;
    padding: 0 0 0.23rem;
    font-size: $font-size-4;
    font-weight: 400;
    border-bottom: 1px solid $border-color-2;

    .email,
    .address {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0.36rem;
    }
  }

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.23rem 0;
    font-size: $font-size-4;
    font-weight: 400;
    line-height: 0.36rem;

    a {
      color: $text-color-3;
    }
  }
}
</style>