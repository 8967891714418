<!-- 开发者平台菜单页面组件 -->
<template>
  <div class="mobile-container mobile-developer-main" v-title="'开发者平台'">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="body-banner">
        <img src="@/assets/image/mobile/photo-54.jpg">
        <div class="banner-content">
          <span class="banner-content-title">开发者平台</span>
          <span>全套WEB环境敏捷开发工具</span>
        </div>
      </div>
      <mobile-developer></mobile-developer>
    </div>
    <!-- <div class="footer">
      <mobile-footer></mobile-footer>
    </div> -->
    <!-- 返回主页 -->
    <div class="mobile-home" @click="back">
      <img src="@/assets/image/mobile/icon-32.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  // 内容
  import MobileDeveloper from '@/components/mobile/mobile-developer/mobile-developer'
  // 底部
  import MobileFooter from '@/components/mobile/mobile-footer/mobile-footer'

  export default {
    name: 'MobileDeveloperMain',
    components: {
      MobileHeader,
      MobileDeveloper,
      MobileFooter
    },
    methods: {
      back () {
        this.$router.push({
          path: '/mobileHome'
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-developer-main.mobile-container {
    background-color: $white-color;
    .mobile-developer {
      padding-top: 1rem;
    }
  }
</style>
